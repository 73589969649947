.App {
    background-color: #132847;
    min-height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Bruno Ace SC', cursive;
}
.title{

  font-size: xx-large;
}
.flex-container {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.image-container {
  position: relative;
  display: inline-block;
}
.image-container .text-overlay1 {
  position: absolute;
  top: 20%;
  left: 50%;
  padding: 25px;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size:8vw;
  font-family: 'Vina Sans', cursive;
}
.image-container .text-overlay2 {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 3vw;
  font-family: 'Bruno Ace SC', cursive;
}
.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #00ffcc58, #0948a6a1, #ff00cc65);
  background-size: 400% 400%;
  animation: gradient-animation 8s ease-in-out infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.image-container img {
  display: block;
  max-width: 100%;
  height: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #132847;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
